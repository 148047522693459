<template lang="html">
  <div>
    <v-card-text>
      <v-row id="pegawai">
        <v-col style="background-color: white;margin: 0px 0px 12px 12px;">
          <div class="ma-3 d-flex justify-center align-center flex-column">
            <v-avatar tile size="164"> <v-img :src="hasil.data_pegawai.foto"></v-img></v-avatar>
            <h3 class="mt-1 orange--text text--darken-2 headline">{{hasil.data_pegawai.nama_pegawai}}</h3>
            <div class="mt-1 grey--text">NIP. {{hasil.data_pegawai.nip_pegawai}}</div>
            <div class="mt-1 green--text">{{hasil.data_pegawai.jabatan}}</div>
          </div>
        </v-col>
        <v-col style="background-color: white;margin: 0px 12px 12px 0px;">
          <h2 class="display-2">Keterangan Pengisian</h2>
          <v-simple-table dense>
            <tbody>
              <tr  v-for="(ket,i) in keterangan" :key="i">
                <td>{{ket.isi}}</td>
                <td v-html="ket.ket"></td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div v-for="(v,k,i) in hasil.hasil_review" :key="i" class="my-3">
            <h1 class="primary--text mt-4 mb-2 display-3 font-weight-bold" style="text-align:center;text-transform:uppercase">{{k}}</h1>
            <v-simple-table>
              <tbody>
                <template v-for="(item, j) in v">
                  <tr :key="j"><td style="font-size:1em"> {{item.pertanyaan_review.nomor}}. <span v-html="item.pertanyaan_review.narasi"></span></td></tr>

                  <tr :key="j+'b'" class="orange--text text--darken-2"><td style="font-size:1em">
                    Nilai: <span class="font-weight-medium" v-html="keteranganIdx(item.nilai)"></span>
                  </td></tr>
                </template>
              </tbody>
            </v-simple-table>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props:{
    hasil:Object,
    keterangan:Array,
  },
  methods:{
    keteranganIdx(idx){
      let ket = this.keterangan[idx]
      // return ket.ket
      console.log(ket)
      return ket.ket
    }
  },
  created(){
    console.log(this.hasil)
    console.log(this.keterangan)
  }
}
</script>

<style lang="css" scoped>
/* .fixed {
  position: sticky;
  width: 5em;
  left: 0;
  top: auto;
  z-index: 999;
}
.not-fixed{
  z-index: 0;
} */
</style>
